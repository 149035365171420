import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176')
];

export const server_loads = [0,8,7,9,11,12,15,18,20,21];

export const dictionary = {
		"/(webflow-site)": [169,[23],[24]],
		"/(webflow-site)/academy": [171,[23],[24]],
		"/(webflow-site)/academy/[slug]": [172,[23],[24]],
		"/(migrated)/app": [~41,[5]],
		"/(migrated)/app/applications/new": [~59,[6]],
		"/(migrated)/app/applications/one/[referenceCode]/aml": [~60,[6,8]],
		"/(migrated)/app/applications/one/[referenceCode]/documents": [61,[6,8]],
		"/(migrated)/app/applications/one/[referenceCode]/overview": [~62,[6,8]],
		"/(migrated)/app/applications/one/[referenceCode]/proof-of-identity": [~63,[6,8]],
		"/(migrated)/app/applications/one/[referenceCode]/submitted": [~65,[6,8]],
		"/(migrated)/app/applications/one/[referenceCode]/submit": [~64,[6,8]],
		"/(migrated)/app/applications/one/[referenceCode]/summary": [~66,[6,8]],
		"/(migrated)/app/applications/one/[referenceCode]/valuation": [~67,[6,8]],
		"/(migrated)/app/applications/[referenceCode]/additional-information": [~42,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/aml": [~43,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/documents": [44,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/home-improvements": [~45,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/management-company": [~46,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/mortgage": [~47,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/overview": [~48,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/ownership": [~49,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/personal": [~50,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/proof-of-identity": [~51,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/property": [~52,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/solicitor": [~53,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/submitted": [~55,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/submit": [~54,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/summary": [~56,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/valuation-request": [~58,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/valuation": [~57,[6,7]],
		"/(migrated)/app/calculator": [~68],
		"/(migrated)/app/guide": [~69,[9]],
		"/(migrated)/app/guide/affordability": [70,[9]],
		"/(migrated)/app/guide/apply": [71,[9]],
		"/(migrated)/app/guide/hpi": [72,[9]],
		"/(migrated)/app/guide/mortgage": [73,[9]],
		"/(migrated)/app/guide/valuation": [74,[9]],
		"/(migrated)/app/learn": [75,[5]],
		"/(migrated)/app/learn/(learn)/article/[slug]": [76,[5,10]],
		"/(migrated)/app/learn/(learn)/category/[slug]": [77,[5,10]],
		"/(migrated)/app/learn/(learn)/search": [~78,[5,10]],
		"/(migrated)/app/learn/(learn)/tag/[slug]": [79,[5,10]],
		"/(migrated)/app/logout": [~80,[5]],
		"/(migrated)/app/plan": [~81,[5,11]],
		"/(migrated)/app/plan/(calculator)/calculate": [~82,[12]],
		"/(migrated)/app/plan/(calculator)/income": [~83,[12]],
		"/(migrated)/app/plan/(calculator)/mortgage": [~84,[12]],
		"/(migrated)/app/plan/(calculator)/results": [~85,[12]],
		"/(migrated)/app/profile": [~86,[5,13]],
		"/(migrated)/app/profile/financials": [~87,[5,13]],
		"/(migrated)/app/profile/marketing-preferences": [~88,[5,13]],
		"/(migrated)/app/profile/property": [~89,[5,13]],
		"/(migrated)/app/property/register": [~90,[14]],
		"/(migrated)/app/property/register/intentions": [~91,[14]],
		"/(migrated)/app/property/register/property-details": [~92,[14]],
		"/(migrated)/app/refer": [~93,[5]],
		"/(migrated)/app/signup": [94,[5]],
		"/(migrated)/app/staircasing": [~95,[5]],
		"/(migrated)/app/stamp-duty": [~96],
		"/(migrated)/app/valuation": [~97],
		"/(migrated)/app/you": [~98],
		"/(migrated)/(auth)/auth/confirm": [~25,[2]],
		"/(migrated)/calculator": [~99,[15]],
		"/(migrated)/calculator/financials": [100,[15]],
		"/(migrated)/calculator/property": [101,[15]],
		"/(migrated)/calculator/remortgage": [~102,[15]],
		"/(migrated)/calculator/results": [~103,[15]],
		"/(webflow-site)/category/[slug]": [173,[23],[24]],
		"/(migrated)/contact": [~104,[16]],
		"/(migrated)/contact/thank-you": [105,[16]],
		"/(webflow-site)/housing-associations": [174,[23],[24]],
		"/(migrated)/instruct/broker": [~106,[17]],
		"/(migrated)/instruct/broker/thank-you": [107,[17]],
		"/(migrated)/instruct/rics": [~108,[17]],
		"/(migrated)/instruct/rics/thank-you": [109,[17]],
		"/(migrated)/is-shared-ownership-right-for-me/(form)": [~110,[18,19]],
		"/(migrated)/is-shared-ownership-right-for-me/(form)/dream-home": [~111,[18,19]],
		"/(migrated)/is-shared-ownership-right-for-me/results": [112,[18]],
		"/(migrated)/(auth)/login": [~26,[2]],
		"/(migrated)/(auth)/login/forgot": [~27,[2]],
		"/(migrated)/(auth)/login/password": [~28,[2]],
		"/(migrated)/(auth)/login/reset": [~29,[2]],
		"/(migrated)/(auth)/login/verify": [30,[2]],
		"/(migrated)/(auth)/login/wrong-device": [31,[2]],
		"/(migrated)/maintenance": [113],
		"/(migrated)/(legal)/privacy-policy": [39,[4]],
		"/(migrated)/resales": [~114,[20]],
		"/(migrated)/resales/instant": [~115,[20]],
		"/(migrated)/resales/instant/thank-you": [116,[20]],
		"/(webflow-site)/search": [~175,[23],[24]],
		"/(migrated)/(auth)/signup/application-gateway": [~32,[2]],
		"/(migrated)/signup/calculator": [117,[21]],
		"/(migrated)/signup/calculator/interest/knowledge-level": [118,[21]],
		"/(migrated)/signup/calculator/interest/why": [119,[21]],
		"/(migrated)/signup/calculator/next-steps": [120,[21]],
		"/(migrated)/signup/calculator/plan/reason-for-waiting": [121,[21]],
		"/(migrated)/signup/calculator/plan/staircasing-process": [122,[21]],
		"/(migrated)/signup/calculator/plan/waiting-for-event-details": [124,[21]],
		"/(migrated)/signup/calculator/plan/waiting-for-event": [123,[21]],
		"/(migrated)/signup/calculator/property-details/address": [~125,[21]],
		"/(migrated)/signup/calculator/property-details/confirm": [~126,[21]],
		"/(migrated)/signup/calculator/property-details/provider": [~127,[21]],
		"/(migrated)/signup/calculator/property-details/purchase-info": [128,[21]],
		"/(migrated)/signup/calculator/property-details/unit-info": [129,[21]],
		"/(migrated)/signup/calculator/sign-up/confirm-email": [130,[21]],
		"/(migrated)/signup/calculator/sign-up/details": [~131,[21]],
		"/(migrated)/(auth)/signup/from-website": [~33,[2]],
		"/(migrated)/(auth)/signup/invite": [~34,[2]],
		"/(migrated)/signup/learning": [132,[21]],
		"/(migrated)/signup/learning/email-address": [~133,[21]],
		"/(migrated)/signup/learning/info/finance": [134,[21]],
		"/(migrated)/signup/learning/info/mortgage": [135,[21]],
		"/(migrated)/signup/learning/info/property-value": [136,[21]],
		"/(migrated)/signup/learning/info/selling": [137,[21]],
		"/(migrated)/signup/learning/info/staircasing": [138,[21]],
		"/(migrated)/signup/learning/info/when": [139,[21]],
		"/(migrated)/signup/learning/next-steps": [140,[21]],
		"/(migrated)/signup/learning/ownership": [141,[21]],
		"/(migrated)/signup/learning/plan/when": [142,[21]],
		"/(migrated)/signup/learning/plan/why": [143,[21]],
		"/(migrated)/signup/learning/property-details/address": [~144,[21]],
		"/(migrated)/signup/learning/property-details/confirm": [~145,[21]],
		"/(migrated)/signup/learning/property-details/provider": [~146,[21]],
		"/(migrated)/signup/learning/property-details/purchase-info": [147,[21]],
		"/(migrated)/signup/learning/property-details/unit-info": [148,[21]],
		"/(migrated)/signup/learning/sign-up/confirm-email": [149,[21]],
		"/(migrated)/signup/learning/sign-up/details": [~150,[21]],
		"/(migrated)/signup/staircasing": [~151,[21]],
		"/(migrated)/signup/staircasing/interest/knowledge-level": [152,[21]],
		"/(migrated)/signup/staircasing/interest/why": [153,[21]],
		"/(migrated)/signup/staircasing/plan/affordability": [~154,[21]],
		"/(migrated)/signup/staircasing/plan/reason-for-waiting": [155,[21]],
		"/(migrated)/signup/staircasing/plan/staircase-now": [156,[21]],
		"/(migrated)/signup/staircasing/plan/waiting-for-event-details": [158,[21]],
		"/(migrated)/signup/staircasing/plan/waiting-for-event": [157,[21]],
		"/(migrated)/signup/staircasing/property-details/address": [~159,[21]],
		"/(migrated)/signup/staircasing/property-details/confirm": [~160,[21]],
		"/(migrated)/signup/staircasing/property-details/provider": [~161,[21]],
		"/(migrated)/signup/staircasing/property-details/purchase-info": [162,[21]],
		"/(migrated)/signup/staircasing/property-details/unit-info": [163,[21]],
		"/(migrated)/signup/staircasing/sign-up/confirm-email": [164,[21]],
		"/(migrated)/signup/staircasing/sign-up/details": [~165,[21]],
		"/(migrated)/signup/start": [166,[21]],
		"/(migrated)/(auth)/signup/verify": [~35,[2]],
		"/(migrated)/(calculators)/stamp-duty": [~36,[3]],
		"/(migrated)/(calculators)/stamp-duty/results": [~37,[3]],
		"/(migrated)/(calculators)/stamp-duty/signup": [~38,[3]],
		"/(webflow-site)/tag/[slug]": [176,[23],[24]],
		"/(migrated)/(legal)/terms-and-conditions": [40,[4]],
		"/(migrated)/unsubscribe": [~167,[22]],
		"/(migrated)/unsubscribe/confirmed": [168,[22]],
		"/(webflow-site)/[providerName]": [~170,[23],[24]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';